import React from 'react'

import SubpageLayout from '../components/subpage-layout'
import Seo from '../components/seo'

import melanie from '../images/testimonials/melanie.jpg'
import medium from '../images/testimonials/medium.svg'
import small from '../images/testimonials/small.svg'

const Why = () => (
  <SubpageLayout
    question="Want to join in?"
    fact="Intercept is built on Drupal: the world’s largest open source community."
  >
    <Seo title="Why" />
    <header className="subpage--content-header">
      <h1>Why</h1>
      <h2>
        Intercept is made for libraries by libraries, but in the end, it’s all
        about the customer.
      </h2>
    </header>

    <section>
      <div className="iframe-wrapper">
        <iframe
          className="responsive-iframe"
          src="https://www.youtube.com/embed/X2xqLpm2emk"
          title="Intercept: Library Events Reimagined."
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      <p>
        Libraries are evolving! No longer are they simply a place where books
        are stored – now they’re increasingly a place where learning, in its
        many forms, takes place. In fact, while some libraries have experienced
        a decrease in visitors and circulation, nationwide{' '}
        <a
          href="https://www.imls.gov/research-evaluation/data-collection/public-libraries-survey"
          target="_blank"
          rel="noopener noreferrer"
        >
          event attendance is up 30% over the past ten years
        </a>
        .
      </p>
      <p>
        We decided to level up our library programming and find a better way to
        connect with customers over what matters to them. We envisioned
        Intercept, an event management system for library <strong>C</strong>
        ustomer <strong>E</strong>ngagement and <strong>P</strong>articipation{' '}
        <strong>T</strong>racking. Intercept was designed to support our
        customers while answering some all-too familiar questions libraries have
        been struggling with for years — how do we know what programs our
        customers attend, and even better, how do we give them more of what they
        love?
      </p>
      <p>
        A grant from the John S. and James L. Knight Foundation helped us make
        Intercept a reality. Working with Knight Foundation, we were open and
        committed to sharing our results, and the code they’re built on, with
        all libraries. We partnered with Aten Design Group to utilize their
        development chops and visionary design skills to help us create this
        innovative, open-source project.
      </p>

      <div className="subpage--content-testimonial clearfix">
        <div className="testimonial-image-wrapper">
          <img src={melanie} alt="" className="headshot" />
          <img src={medium} alt="" className="medium svg" />
          <img src={small} alt="" className="small svg" />
        </div>
        <div className="testimonial-text-wrapper">
          <p className="quote">
            Libraries can finally make informed programming decisions based on
            real-time customer feedback and data thanks to Intercept. I’m so
            proud to partner with The Knight Foundation and Aten Design Group to
            make this a reality for libraries everywhere.
          </p>
          <p className="name">Melanie H.</p>
          <p className="byline">Executive Director, Richland Library</p>
        </div>
      </div>

      <h3>Project Intercept's objectives are:</h3>
      <ul>
        <li>
          To better target the needs of the community by providing program
          attendance data
        </li>
        <li>
          To create an easily customizable tool to work with a variety of ILS
          systems and outcome measurement surveys
        </li>
        <li>
          To build an open-source platform to share with libraries and beyond
        </li>
        <li>
          To better understand our attendees in order to improve the outcome,
          reach and quality of library programming
        </li>
      </ul>

      <h3>Why should your library adopt Intercept?</h3>
      <ul>
        <li>You want to offer customers more of what they love.</li>
        <li>
          You have a forward-thinking library that embraces new technologies and
          processes.
        </li>
        <li>
          Your staff are excited to try new things and ask customers to try new
          things.
        </li>
        <li>
          You’re tired of library software and service providers that aren’t
          listening to or developing based on library feedback.
        </li>
        <li>
          You have web developers on staff who can install and maintain
          open-source software OR you are willing to invest a small amount of
          money to use a hosted calendaring Software as a Service (SaaS).
        </li>
        <li>
          You believe in contributing back to the greater library community.
        </li>
      </ul>
    </section>
  </SubpageLayout>
)

export default Why
